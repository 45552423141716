import { useState } from 'react';
import { Row, Col, Drawer } from 'antd';
import { withTranslation } from 'react-i18next';
import Container from '../../common/Container';
import { Button } from '../../common/Button';
import { PngImage } from '../../common/PngImage';
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from './styles';
import { useHistory } from 'react-router-dom';

const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: 'smooth',
      });
      setVisibility(false);
    };

    const history = useHistory();
    const navigateTo = (path: string) => history.push(path);

    return (
      <>
        <CustomNavLinkSmall onClick={() => navigateTo('aboutUs')}>
          <Span>{t('About Us')}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => navigateTo('faq')}>
          <Span>{t('FAQ')}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall style={{ width: '180px' }} onClick={() => scrollTo('contact')}>
          <Span>
            <Button>{t('Contact')}</Button>
          </Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <PngImage src="nomadacq_official.png" width="250px" height="75px" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} visible={visible} onClose={onClose}>
          <Col style={{ marginBottom: '2.5rem' }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
