const routes = [
  {
    path: ['/', '/home'],
    exact: true,
    component: 'Home',
  },
  {
    path: ['/', '/aboutUs'],
    exact: true,
    component: 'AboutUs',
  },
  {
    path: ['/', '/faq'],
    exact: true,
    component: 'FAQ',
  },
];

export default routes;
